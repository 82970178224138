<template>
  <div class="result">
    <div style="text-align: center">
      <button
        style="padding: 5px 10px;background-color:#7ee47e;margin: 10px;font-weight:bold,font-size:18px;cursor:pointer"
        @click="toPdf"
      >
        <i style="margin-right: 5px; font-size: 19px" class="fa fa-download"></i
        >Télécharger PDF
      </button>
    </div>
    <div style="text-align: center">
      <h3 v-if="succes == 'true'" style="color: green">
        Vous avez réussi test de {{ LibelleMatiere }} {{ LibelleNiveau }}
      </h3>
      <div
        v-if="succes == 'true' && idNv != 7 && idNv != 14 && idNv != 20"
        style="text-align: center"
      >
        <button
          style="padding: 5px 10px;background-color:#7ee47e;margin: 10px;font-weight:bold,font-size:18px;cursor:pointer"
          @click="next"
        >
          <i style="margin-right: 5px; font-size: 19px" class="fa fa-next"></i>Passez au
          niveau suivant
        </button>
        <button
          style="padding: 5px 10px;background-color:#7ee47e;margin: 10px;font-weight:bold,font-size:18px;cursor:pointer;background-color:red"
          @click="next"
        >
          <i style="margin-right: 5px; font-size: 19px" class="fa fa-exit"></i>Quitter
        </button>
      </div>
      <img
        v-if="succes == 'true'"
        class="fele"
        style="width: 10%"
        src="@/assets/fele.png"
        alt=""
      />
      <h3 v-if="succes == 'false'" style="color: red">
        Vous n'avez pas réussi test de {{ LibelleMatiere }} {{ LibelleNiveau }} ,Votre
        niveau est {{ LibelleNiveau }}:
      </h3>
      <img
        v-if="succes == 'false'"
        class="fele"
        style="width: 10%"
        src="@/assets/sad.png"
        alt=""
      />
    </div>
    <div id="pdf" style="width: 1000px; margin: auto; padding: 50px 0; padding-top: 0">
      <div style="display: flex; align-items: center">
        <div style="text-align: center; width: fit-content">
          <img
            id="img"
            style="width: 200px"
            src="https://api.victory-partners.ma/victory%20api/upload/images/icons/logo.png"
            alt=""
          />
          <div style="width: fit-content; display: table-cell">
            Bienvenue au centre VICTORY-Partners
          </div>
        </div>
        <h1 style="margin: 10px auto">Test concernant {{ NomClient }}</h1>
      </div>

      <div
        style="
          text-align: center;
          display: grid;
          grid-template-columns: 33% 33% 33%;
          margin: 10px 0;
          font-size: 18px;
        "
      >
        <span style="text-align: center">Matière: {{ LibelleMatiere }}</span>
        <span style="text-align: center">Niveau: {{ LibelleNiveau }}</span>
        <span style="text-align: center">Date de test: {{ date }}</span>
      </div>
      <div style="text-align: center; font-size: 18px; font-weight: bold; margin: 20px 0">
        Résultat Test: {{ result }}
      </div>
      <table
        border="1"
        style="width: 100%; border-collapse: collapse; margin-bottom: 30px"
      >
        <tr>
          <th></th>
          <th>Enoncé</th>
          <th>La bonne réponse</th>
          <th>Votre réponse</th>
          <th>Résultat</th>
        </tr>
        <tr v-for="(res, i) in response.comp" :key="res.idComp">
          <td v-if="i == 0" :rowspan="response.comp.length" style="">
            <div
              style="
                margin-left: -90px;
                position: absolute;
                width: 215px;
                transform: rotate(-90deg);
                -webkit-transform: rotate(-90deg); /* Safari/Chrome */
                -moz-transform: rotate(-90deg); /* Firefox */
                -o-transform: rotate(-90deg); /* Opera */
                -ms-transform: rotate(-90deg);
                font-weight: bold;
              "
            >
              Compréhension écrite
            </div>
          </td>
          <td>{{ res.enonce }}</td>
          <td>{{ res.bonne }}</td>
          <td>{{ res.result ? res.result : "null" }}</td>
          <td style="text-align: center">
            <img
              v-if="res.bonne == res.result"
              style="height: 40px"
              src="https://api.victory-partners.ma/victory%20api/upload/images/icons/true.png"
              alt=""
            />
            <img
              v-if="res.bonne != res.result"
              style="height: 40px"
              src="https://api.victory-partners.ma/victory%20api/upload/images/icons/false.png"
              alt=""
            />
          </td>
        </tr>
        <tr v-for="(res, i) in response.qsm" :key="res.idQCM">
          <td v-if="i == 0" :rowspan="response.qsm.length" style="position:relative">
            <div
              style="
                margin-left: -90px;
                position: absolute;
                width: 215px;
                transform: rotate(-90deg);
                -webkit-transform: rotate(-90deg); /* Safari/Chrome */
                -moz-transform: rotate(-90deg); /* Firefox */
                -o-transform: rotate(-90deg); /* Opera */
                -ms-transform: rotate(-90deg);
                font-weight: bold;
                top: 100px;
              "
            >
              Structure de la langue
            </div>
          </td>
          <td>{{ res.enonce }}</td>
          <td>{{ res.bonne }}</td>
          <td>{{ res.result ? res.result : "null" }}</td>
          <td style="text-align: center">
            <img
              v-if="res.bonne == res.result"
              style="height: 40px"
              src="https://api.victory-partners.ma/victory%20api/upload/images/icons/true.png"
              alt=""
            />
            <img
              v-if="res.bonne != res.result"
              style="height: 40px"
              src="https://api.victory-partners.ma/victory%20api/upload/images/icons/false.png"
              alt=""
            />
          </td>
        </tr>
        <tr v-for="(res, i) in response.audio" :key="res.idAudio">
          <td v-if="i == 0" :rowspan="response.audio.length">
            <div
              style="
                margin-left: -90px;
                position: absolute;
                width: 215px;
                transform: rotate(-90deg);
                -webkit-transform: rotate(-90deg); /* Safari/Chrome */
                -moz-transform: rotate(-90deg); /* Firefox */
                -o-transform: rotate(-90deg); /* Opera */
                -ms-transform: rotate(-90deg);
                font-weight: bold;
              "
            >
              Compréhension orale
            </div>
          </td>
          <td>{{ res.enonce }}</td>
          <td>{{ res.bonne }}</td>
          <td>{{ res.result ? res.result : "null" }}</td>
          <td style="text-align: center">
            <img
              v-if="res.bonne == res.result"
              style="height: 40px"
              src="https://api.victory-partners.ma/victory%20api/upload/images/icons/true.png"
              alt=""
            />
            <img
              v-if="res.bonne != res.result"
              style="height: 40px"
              src="https://api.victory-partners.ma/victory%20api/upload/images/icons/false.png"
              alt=""
            />
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { Rest } from "../providers/rest";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import router from "@/router";
window.onbeforeunload = null;
export default {
  name: "Result",
  data() {
    return {
      idTest: this.$route.params.idTest,
      result: this.$route.params.result,
      succes: this.$route.params.succes,
      response: JSON.parse(this.$route.params.response) ?? null,
      NomClient: "",
      idClient: null,
      date: "",
      LibelleMatiere: "",
      LibelleNiveau: "",
      idMatiere: null,
      idNv: null,
    };
  },
  beforeMount() {
    if (!this.$route.params.idTest) {
      this.$router.replace("/");
    } else {
      new Rest().getTest(this.idTest).then((r) => {
        this.NomClient = r.data.data.NomClient;
        this.idClient = r.data.data.idClient;
        this.LibelleMatiere = r.data.data.LibelleMatiere;
        this.idMatiere = r.data.data.idMatiere;
        this.LibelleNiveau = r.data.data.LibelleNiveau;
        this.idNv = r.data.data.idNiveau;
        this.date = r.data.data.DateTest;
      });
    }
  },
  methods: {
    toPdf() {
      /*html2canvas(document.getElementById("pdf"), {imageTimeout: 5000, useCORS: true}).then(canvas => {
                const img = canvas.toDataURL('image/png')
                console.log(img)
                const doc = new jsPDF();
                doc.addImage(img, 'JPEG', 5, 5, 200, 287)
                doc.save(this.NomClient + '.pdf');
            })*/
      /* const url = document.getElementById('img').src;
            window.open(url,'Image','width=largeImage.stylewidth,height=largeImage.style.height,resizable=1');*/
      const pdf = document.getElementById("pdf").innerHTML;
      let style = "<style>";
      style =
        style + "td,th{padding: 8px 3px;border-color:black;overflow-wrap: break-word;}";
      style = style + "td:nth-child(1),th:nth-child(1){width: 5%;max-width: 450px;}";
      style = style + "td:nth-child(2),th:nth-child(2){width: 47%;max-width: 450px;}";
      style = style + "td:nth-child(3),th:nth-child(3){width: 20%;max-width: 186px;}";
      style = style + "td:nth-child(4),th:nth-child(4){width: 20%;max-width: 186px;}";
      style = style + "td:nth-child(5),th:nth-child(5){width: 8%;max-width: 71px;}";
      style = style + "th{background-color:gray}th:nth-child(2){background-color:green}";
      style = style + "</style>";
      const win = window.open("", "", "height=700,width=700");
      win.document.write("<html><head>");
      win.document.write("<title>" + this.NomClient + "</title>");
      win.document.write(style);
      win.document.write("</head>");
      win.document.write("<body>");
      win.document.write(pdf);
      win.document.write("</body></html>");
      win.document.close();
      win.print();
    },
    next() {
      router.push("/main/" + this.idMatiere + "/" + this.idClient + "/start");
    },
  },
};
</script>

<style>
#nav {
  display: flex;
}
@media screen and (max-width: 768px) {
  #nav {
    text-align: center;
    display: block;
  }
  #nav ul li {
    float: none;
  }
  #nav ul {
    padding-right: 0;
  }
  #logo {
    padding: 0;
    width: 100%;
  }
  #logo img,
  #logo span {
    margin: auto;
  }
  .fele {
    width: 60% !important;
  }
}
td,
th {
  padding: 8px 3px;
  border-color: black;
  overflow-wrap: break-word;
}
td:nth-child(1),
th:nth-child(1) {
  width: 5%;
  max-width: 450px;
}
td:nth-child(2),
th:nth-child(2) {
  width: 47%;
  max-width: 450px;
}
td:nth-child(4),
th:nth-child(4) {
  width: 20%;
  max-width: 186px;
}
td:nth-child(3),
th:nth-child(3) {
  width: 20%;
  max-width: 186px;
}
td:nth-child(5),
th:nth-child(5) {
  width: 8%;
  max-width: 71px;
}
th {
  background-color: gray;
}
th:nth-child(2) {
  background-color: green;
}
</style>
